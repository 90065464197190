<template>
  <div>
    <h6>data: {{ data }}</h6>
    <h6>next: {{ nextOperator }}</h6>
    <h6>dataResult: {{ dataResult }}</h6>
    <h1>Calc</h1>
    <v-row>
      <v-col>{{ opDisplay }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <app-text-field v-model="display"></app-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="flex-column">
        <app-btn
          v-for="number in 10"
          :key="number"
          :value="number - 1"
          min-width="50"
          @click="handleDigit(number - 1)"
          >{{ number - 1 }}</app-btn
        >
      </v-col>
      <v-col
        ><app-btn
          v-for="op in operators"
          :key="op"
          min-width="50"
          @click="handleOperator(op)"
          >{{ op }}</app-btn
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="">
        <app-btn @click="handleEqual">=</app-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  name: 'Calc',

  setup() {
    const dataResult = ref(0)
    const data = ref([])
    const display = ref(0)
    const opDisplay = ref('')
    const operators = ref(['+', '-'])
    const nextOperator = ref(false)

    const handleDigit = (number) => {
      if (display.value === 0) {
        display.value = number
      } else {
        const value = display.value + number.toString()
        display.value = parseInt(value)
      }
    }

    const handleOperator = (operator) => {
      opDisplay.value = display.value + operator
      if (data.value.length === 0) {
        data.value.push(display.value)
        nextOperator.value = operator
      } else {
        data.value.push(parseInt(nextOperator.value + display.value))
        nextOperator.value = operator
      }
      display.value = 0
    }

    const handleEqual = () => {
      if (display.value !== 0) {
        data.value.push(parseInt(nextOperator.value + display.value))
        dataResult.value = data.value.reduce((a, b) => a + b)
        display.value = 0
        nextOperator.value = false
      }
    }

    return {
      display,
      opDisplay,
      data,
      operators,
      nextOperator,
      dataResult,
      handleDigit,
      handleOperator,
      handleEqual
    }
  }
}
</script>

<style></style>
